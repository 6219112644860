
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}
